function headerBefore() {
    /**
     * @type {NodeListOf<HTMLElement>}
     */
    const pinkHeader = document.querySelectorAll('.pink-header');

    pinkHeader.forEach(header => {
        if (header.offsetHeight > 95) {
            header.classList.add('large')
        } else {
            header.classList.remove('large');
        }
    })
}

window.addEventListener('load', () => {
    headerBefore();
})

window.addEventListener('resize', () => {
    headerBefore();
})