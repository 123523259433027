import {onLoad} from "../base/onLoad";

window.location_filter_state = {};

onLoad(() => {
    const filter =  document.querySelector('.filter-context');

    if(!filter) return;

    const filterItems =  filter.querySelectorAll('.filter-item');
    const posts = document.querySelectorAll('.locatie-item');

    function updateFilterState() {
        filterItems.forEach(filterItem => {
            if(filterItem.classList.contains('filter-item-select')) {
                const input =  filterItem.querySelector('select');
                window.location_filter_state[input.name] = input.value;
            } else if(filterItem.classList.contains('filter-item-checkbox')) {
                const name = filterItem.getAttribute('data-key');
                const inputs =  filterItem.querySelectorAll('input:checked');
                const output = [];
                inputs.forEach(input => {
                    output.push(input.value);
                });
                window.location_filter_state[name] = output;
            } else if(filterItem.classList.contains('filter-item-range')) {
                const input =  filterItem.querySelector('input');
                window.location_filter_state[input.name] = parseInt(input.value);
            }
        });

        filterPosts();
    }

    updateFilterState();

    filterItems.forEach(i=> {
        i.querySelectorAll('input, select').forEach(input => {
            input.addEventListener('change', updateFilterState);
        });
    })


    function filterPosts() {
        const filterState =  window.location_filter_state;

        posts.forEach(post => {
            const filterData = JSON.parse(post.getAttribute('data-filter'));
            let show = true;
            for(const [key, value] of Object.entries(filterState)) {
                // check if value is an empty string
                if(value === '' || (Array.isArray(value) && value.length === 0)) {
                    continue;
                }

                const postValue = filterData[key];

                // check if value  is an array
                if(Array.isArray(value)) {
                    value.forEach(v => {
                        if(!postValue.includes(v)) {
                            show = false;
                        }
                    });
                    if(!show) {
                        break;
                    }
                } else if(Number.isInteger(value))  {
                    if(parseInt(postValue) > value )  {
                        show = false;
                        break;
                    }
                } else {
                    if(value !== postValue) {
                        show = false;
                        break;
                    }
                }
            }

            if(show) {
                post.classList.remove('hidden');
            } else {
                post.classList.add('hidden');
            }
        })
    }
});
