import {onLoad} from "../base/onLoad";
import {Splide} from "@splidejs/splide";

onLoad(() => {
    function createSplide(selector, leftArrow, rightArrow, options, extensions = {}) {

        if (!document.querySelector < HTMLElement > (selector)) {
            return;
        }

        const splide = new Splide(selector, options);

        splide.mount(extensions);

        if (leftArrow) {
            leftArrow.addEventListener("click", () => {
                splide.go('<');
            });
        }

        if (rightArrow) {
            rightArrow.addEventListener("click", () => {
                splide.go('>');
            });
        }
    }

    const selector = ".locatie-slider";
    /**
     * @type {NodeListOf<HTMLElement>}
     */
    const sliders = document.querySelectorAll(selector);

    sliders.forEach(slider => {

        const leftArrow = slider.querySelector(".arrows .left");
        const rightArrow = slider.querySelector(".arrows .right");

        createSplide(slider, leftArrow, rightArrow, {
            type: 'slider',
            perPage: 1,
            updateOnMove: true,
            perMove: 1,
            waitForTransition: true,
            focus: 'center',
            flickMaxPages: 1,
            pagination: true,
            arrows: false,
        });
    })

    /**
     * @type {Object<HTMLElement>}
     */
    const headerSlider = document.querySelector('.header-slider');

    if (!!headerSlider) {

        const leftArrow = headerSlider.querySelector(".arrows .left");
        const rightArrow = headerSlider.querySelector(".arrows .right");

        createSplide(headerSlider, leftArrow, rightArrow, {
            type: 'loop',
            perPage: 1,
            pagination: false,
            arrows: false,
            autoplay: true,
            interval: 5000,
        });
    }
})

