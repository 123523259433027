import {onLoad} from '../base/onLoad';
import AOS from 'aos';

onLoad(() => {
    window.initAnimations();
});

window.initAnimations = function() {
    AOS.init({
        duration: 1000,
        once: true
    });
}

window.refreshAnimations = function() {
    AOS.refresh();
}