import {onLoad} from "../base/onLoad";

window.filterState = {};

onLoad(() => {
    const filterItemElements = document.querySelectorAll('[data-sort] [data-key]');
    const filterItems = [];
    filterItemElements.forEach(item => {
        filterItems.push(new FilterItem(item))
    });

    const filterPosts = document.querySelectorAll('[data-filter]');

    document.addEventListener('update-filter', () => {
        filterPosts.forEach(post => {
            let show = true;
            const postData = post.getAttribute('data-filter');
            const postDataJson = JSON.parse(postData);

            filterItems.forEach(item => {
                if (!item.validate(postDataJson)) {
                    show = false;
                }
            })

            if (show) {
                post.style.display = 'block';
            } else {
                post.style.display = 'none';
            }
        })
    })

})

class FilterItem {
    /**
     * @param el {HTMLElement}
     */
    constructor(el) {
        this.el = el;
        this.key = this.el.getAttribute('data-key');
        /** @type {string[]} */
        this.value = this.getValue();
        this.type = this.el.getAttribute('data-filter-item-type');

        this.setFromUrl();

        this.addEventListeners('change', () => {
            this.value = this.getValue();
            this.setState();
            const event = new Event('update-filter');
            document.dispatchEvent(event);

            this.addValueToUrl();
        });
    }

    addEventListeners(type, callback) {
        this.getInput().forEach(input => {
            input.addEventListener(type, callback);
        });
    }

    setState() {
        window.filterState[this.key] = this.value;
    }

    getValue() {
        let value = [];

        switch (this.type) {
            case 'checkbox':
                return this.getCheckboxValues();
            case 'select':
                value = this.el.querySelector('select').value;
                return value === '' ? [] : [value];
            case 'range':
                value = this.el.querySelector('input').value;
                return value === '' ? [] : [value];
        }

        return [];
    }

    /**
     * @param value {string[]}
     */
    setValue(value) {
        switch (this.type) {
            case 'checkbox':
                this.getInput().forEach(input => {
                    input.checked = value.includes(input.value);
                });
                break;
            case 'select':
                if (value.length > 0) {
                    this.el.querySelector('select').value = value[0];
                }
                break;
            case 'range':
                if (value.length > 0) {
                    this.el.querySelector('input').value = value[0];
                }
                break;
        }
    }

    getCheckboxValues() {
        if (this.type !== 'checkbox') {
            return [];
        }

        const checked = this.el.querySelectorAll('input:checked');

        const output = [];
        checked.forEach(input => {
            if (input.checked) {
                output.push(input.value);
            }
        });

        return output;
    }

    getInput() {
        switch (this.type) {
            case 'checkbox':
                return this.el.querySelectorAll('input');
            case 'select':
                return this.el.querySelectorAll('select');
            case 'range':
                return this.el.querySelectorAll('input');
        }
    }

    validate(filterData) {
        const value = filterData[this.key];

        if (!value) {
            return false;
        }

        if (this.value.length === 0) {
            return true;
        }

        if (this.type === 'checkbox') {
            return this.value.every(item => value.includes(item));
        }
        if (this.type === 'select') {
            return this.value.some(item => value === item);
        }
        if (this.type === 'range') {
            const postValue = parseInt(value);
            const filterValue = parseInt(this.value[0]);
            return postValue <= filterValue;
        }

        return false;
    }

    addValueToUrl() {
        const url = new URL(window.location.href);
        url.searchParams.set(this.key, this.value.join(','));
        window.history.replaceState({}, '', url.href);
    }

    setFromUrl() {
        const url = new URL(window.location.href);
        const value = url.searchParams.get(this.key);

        if (value) {
            this.value = value.split(',');
            this.setValue(this.value);
        }
    }
}