import './aos-init';
import './menu';
import './diensten-slider';
import './scrolled';
import './gallery';
import './header';
import './locatie-slider';
import './locatie-filter';
// import './sort';
import './filter';
import './phone-menu';