import {onLoad} from "../base/onLoad";

onLoad(() => {
    const scrollElements = document.querySelectorAll('[data-scrolled]');

    window.addEventListener('scroll', function(e) {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if(scrollTop>0) {
            scrollElements.forEach(item => {
                item.classList.add('scrolled');
            })
        } else {
            scrollElements.forEach(item => {
                item.classList.remove('scrolled');
            })
        }
    });
});