import {onLoad} from "../base/onLoad";

onLoad(() => {
    // menu toggles
    const toggles = document.querySelectorAll('[menu-toggle]');
    // menu items
    const menu = document.querySelector('[menu]');

    // loop over toggles
    toggles.forEach(toggle => {
        // on click
        toggle.addEventListener('click', () => {
            // toggle menu
            menu.classList.toggle('open');
        });
    });


    const dropdowns = document.querySelectorAll('.dropdown-container');
    dropdowns.forEach(dropdown => {
        const trigger = dropdown.querySelector('.trigger');
        trigger.addEventListener('click', () => {
            dropdown.classList.toggle('open');
        });
    });
})