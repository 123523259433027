 import {onLoad} from "../base/onLoad";

onLoad(() => {
    const max = 6;
    const galleries = document.querySelectorAll('[data-gallery]');
    galleries.forEach(gallery => {
        const LoadMoreButton = gallery.querySelector('[data-gallery-load-more]');
        const imageContainer = gallery.querySelector('[data-gallery-images]');
        const jsonElement = gallery.querySelector('[data-gallery-json]');
        const links = gallery.querySelector('[data-gallery-links]');

        let images = JSON.parse(jsonElement.innerHTML);
        jsonElement.remove();

        if(images.length > 0) {
            showElement(LoadMoreButton);
        } else {
            hideElement(LoadMoreButton);
        }

        LoadMoreButton.addEventListener('click', () => {
            let newImages = [];
            let renderImages = [];
            for (let i = 0; i < images.length; i++) {
                if (i < max) {
                    renderImages.push(images[i]);
                } else {
                    newImages.push(images[i]);
                }
            }

            images = newImages;

            renderImages.forEach(image => {
                const img = getImageElement(image);
                imageContainer.appendChild(img);
            });

            if(images.length > 0) {
                showElement(LoadMoreButton);
            } else {
                hideElement(LoadMoreButton);
            }
        });

        // on image click
        imageContainer.addEventListener('click', (e) => {
            const target = e.target;
            if(target.tagName === 'IMG') {
                // get index of image
                const index = Array.prototype.indexOf.call(imageContainer.children, target);

                blueimp.Gallery(links.querySelectorAll('a'), {
                    index: index,
                });
            }
        });
    });

    function showElement(element) {
        element.style.display = 'block';
    }

    function hideElement(element) {
        element.style.display = 'none';
    }

    function getImageElement(data) {
        const {url, alt} = data;
        const image = document.createElement('img');
        image.src = url;
        image.alt = alt;
        return image;
    }
});