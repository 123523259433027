import {onLoad} from "../base/onLoad";
import {Splide} from "@splidejs/splide";

onLoad(() => {
    const selector = ".diensten-slider";
    const sliders = document.querySelectorAll(selector);

    sliders.forEach(slider => {
        const amountOfSlides = slider.querySelectorAll(".splide__slide").length;
        const getCenterFromAmountOfSlides = (amountOfSlides/2)===Math.floor(amountOfSlides/2)?amountOfSlides/2:Math.floor(amountOfSlides/2)+1;

        const splide = new Splide( slider, {
            type   : 'carousel',
            trimSpace: true,
            perPage: 3,
            arrows: false,
            updateOnMove: true,
            perMove: 1,
            waitForTransition: true,
            focus  : 'center',
            gap: '15px',
            padding: 0,
            flickMaxPages: 1,
            start: getCenterFromAmountOfSlides-1,
            breakpoints: {
                992: {
                    perPage:1,
                    padding: '20%'
                },
                575: {
                    perPage: 1,
                    padding: '10%'
                }
            }
        } );

        splide.mount();
    });
})