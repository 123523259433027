window.addEventListener("load", function () {
    const button = document.querySelector('.phone-menu-button');
    const menu = document.querySelector('.phone-menu-wrapper');
    const menuClose = menu.querySelector('.phone-menu-close');

    button?.addEventListener('click', () => {
        menu?.classList.add('open');
        document.body.style.overflowY = 'hidden';
    })

    menuClose?.addEventListener('click', () => {
        menu?.classList.remove('open');
        document.body.style.overflowY = 'visible';
    })
})